import { Theme } from '@mui/material/styles';

export default function ToggleButtonGroup(theme: Theme) {
  return {
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
        },
        firstButton: {
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          margin: 0,
          '&.Mui-error': {
            borderTopColor: theme.palette.error.main,
            borderBottomColor: theme.palette.error.main,
            borderLeftColor: theme.palette.error.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.secondary.lighter,
            border: `1px solid ${theme.palette.secondary.light}`,
          },
          '&.Mui-error.Mui-disabled': {
            border: `1px solid ${theme.palette.error.main}`,
            borderRight: `1px solid ${theme.palette.secondary.light}`,
          },
        },
        middleButton: {
          borderRadius: 0,
          margin: 0,
          '&.Mui-error': {
            borderTopColor: theme.palette.error.main,
            borderBottomColor: theme.palette.error.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.secondary.lighter,
            borderRight: `1px solid ${theme.palette.secondary.light}`,
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            borderBottom: `1px solid ${theme.palette.secondary.light}`,

            borderLeft: `1px solid ${theme.palette.secondary.light}`,
          },
          '&.Mui-error.Mui-disabled': {
            borderTop: `1px solid ${theme.palette.error.main}`,
            borderBottom: `1px solid ${theme.palette.error.main}`,
          },
        },
        lastButton: {
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
          margin: 0,
          '&.Mui-error': {
            borderTopColor: theme.palette.error.main,
            borderBottomColor: theme.palette.error.main,
            borderRightColor: theme.palette.error.main,
          },
          '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.secondary.lighter,
            borderRight: `1px solid ${theme.palette.secondary.light}`,
            borderTop: `1px solid ${theme.palette.secondary.light}`,
            borderBottom: `1px solid ${theme.palette.secondary.light}`,

            borderLeft: `1px solid ${theme.palette.secondary.light}`,
          },
          '&.Mui-error.Mui-disabled': {
            borderRightColor: theme.palette.error.main,
            borderTopColor: theme.palette.error.main,
            borderBottomColor: theme.palette.error.main,
          },
        },
      },
    },
  };
}
