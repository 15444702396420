import { Button, ButtonProps, Tooltip } from '@mui/material';
import { MoreVerticalIcon } from '@components/actionicons';
import { PopoverMenu, PopoverMenuLabelledItemT } from '@components/ng/popovermenu';

export type ContextMenuProps<Key extends string> = {
  size?: 'small' | 'medium';
  disabled?: boolean;
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  options?: PopoverMenuLabelledItemT<Key>[];
  title?: string;
  onClick?: (key: Key) => void;
  autoClose?: boolean;
  children?: React.ReactElement;
};

export function ContextMenu<Key extends string>({
  title,
  size = 'medium',
  variant = 'outlinedAlt',
  color = 'inherit',
  disabled,
  options,
  onClick,
  autoClose,
  children = <MoreVerticalIcon />,
}: ContextMenuProps<Key>) {
  if (!options || options.length === 0 || !onClick) {
    return null;
  }

  return (
    <PopoverMenu<Key, PopoverMenuLabelledItemT<Key>>
      position="bottom_left"
      items={options}
      onMenuItemClick={onClick}
      autoClose={autoClose}
    >
      {({ open }) => (
        <Tooltip
          title={title}
          placement="left"
          arrow
        >
          <span>
            <Button
              size={size === 'medium' ? 'mediumSquare' : 'smallSquare'}
              disabled={disabled}
              variant={variant}
              color={color}
              onClick={open}
            >
              {children}
            </Button>
          </span>
        </Tooltip>
      )}
    </PopoverMenu>
  );
}
