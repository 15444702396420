import type { Theme } from '@mui/material/styles';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiSvgIcon-root': {
            color: theme.palette.grey[300], // chevron color
          },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px',
          },
          '& .MuiOutlinedInput-root .MuiInputBase-inputAdornedStart.MuiAutocomplete-input': {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
        input: {
          marginTop: '1px',
          marginBottom: '1px',
        },
        inputRoot: {
          padding: '6px',
          paddingLeft: 0,
          color: theme.palette.grey[700],
          '&::placeholder': {
            color: theme.palette.grey[300],
          },
        },
        hasPopupIcon: {
          '& .MuiOutlinedInput-root': {
            paddingRight: '34px !important',
          },
        },
        hasClearIcon: {
          '& .MuiOutlinedInput-root': {
            paddingRight: '54px !important',
          },
        },
        popupIndicator: {
          width: 'auto',
          height: 'auto',
        },
        clearIndicator: {
          width: 'auto',
          height: 'auto',
          visibility: 'visible',
        },
        paper: {
          borderRadius: 4,
          margin: '4px 0',
          padding: '4px 0',
          boxShadow: theme.customShadows.popover,
        },
        noOptions: {
          ...theme.typography.bodyRegular,
          padding: '7px 12px',
        },
        loading: {
          ...theme.typography.bodyRegular,
          padding: '7px 12px',
        },
        option: {
          ...theme.typography.bodyRegular,
          padding: '7px 12px !important',
          minHeight: '0px !important',

          '&:hover': {
            backgroundColor: `${theme.palette.secondary[100]} !important`,
          },

          '&[aria-selected="true"]': {
            backgroundColor: `${theme.palette.grey.A800} !important`,
          },
        },
        listbox: {
          padding: 0,
          minHeight: '0px !important',
          maxHeight: '305px !important',
        },
        tag: {
          margin: '0px',
        },
      },
    },
  };
}
