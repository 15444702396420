export default function MonthCalendar() {
  return {
    MuiMonthCalendar: {
      styleOverrides: {
        root: {
          padding: 0,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: 'repeat(3, auto)',
          maxHeight: '236px',
          maxWidth: '256px',
        },
      },
    },
  };
}
