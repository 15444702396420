import { ApolloProvider } from '@apollo/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import config from '@config';
import { ReloadPromptWrapper } from '@components/mui/reloadprompt';
import { RichTextEditorContextProvider } from '@components/mui/richtexteditor';
import { PdfViewerLicenseProvider } from '@components/ng/pdfviewer';
import { ServiceWorkerContextProvider } from '@components/serviceworker';
import { AuthContextProvider } from '@features/auth';
import { client as apolloClient } from './apollo';
import { DateContext, IntlContext, NonceContextProvider, StyledContext } from './context';
import { client as reactQueryClient } from './react-query';
import { router } from './router';

export default function App() {
  const [ready, setReady] = useState(false);

  if (config.app.apolloDebug) {
    import('@apollo/client/dev').then(({ loadDevMessages, loadErrorMessages }) => {
      loadDevMessages();
      loadErrorMessages();
      setReady(true);
    });
  } else {
    setReady(true);
  }

  // retrieve the CSP nonce
  let nonce;
  const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
  if (nonceMetaTag) {
    nonce = nonceMetaTag.getAttribute('content') ?? undefined;
  }

  return (
    <>
      {ready && (
        <NonceContextProvider
          value={{
            nonce,
          }}
        >
          <RichTextEditorContextProvider value={{ nonce }}>
            <ApolloProvider client={apolloClient}>
              <QueryClientProvider client={reactQueryClient}>
                <IntlContext>
                  <StyledContext>
                    <DateContext>
                      <ServiceWorkerContextProvider>
                        <ReloadPromptWrapper />
                        <AuthContextProvider>
                          <PdfViewerLicenseProvider licenseKey={config.syncfusion.licenseKey}>
                            <RouterProvider router={router} />
                          </PdfViewerLicenseProvider>
                        </AuthContextProvider>
                      </ServiceWorkerContextProvider>
                    </DateContext>
                  </StyledContext>
                </IntlContext>
              </QueryClientProvider>
            </ApolloProvider>
          </RichTextEditorContextProvider>
        </NonceContextProvider>
      )}
    </>
  );
}
