import { Theme } from '@mui/material/styles';

export default function PickersMonth(theme: Theme) {
  return {
    MuiPickersMonth: {
      styleOverrides: {
        root: {},
        monthButton: {
          ...theme.typography.body2Description,
          margin: 0,
          borderRadius: '6px',
          height: '26px',
          width: '44px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            '&:focus': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },
  };
}
