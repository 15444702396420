import { PopoverOrigin } from '@mui/material';
import type { PopoverPosition } from './types';

const POSITION_MAP: Record<PopoverPosition, PopoverOrigin> = {
  top_center: { vertical: 'top', horizontal: 'center' },
  top_left: { vertical: 'top', horizontal: 'left' },
  top_right: { vertical: 'top', horizontal: 'right' },
  bottom_center: { vertical: 'bottom', horizontal: 'center' },
  bottom_left: { vertical: 'bottom', horizontal: 'left' },
  bottom_right: { vertical: 'bottom', horizontal: 'right' },
};

export function getPopoverAnchorOrigin(position: PopoverPosition) {
  return POSITION_MAP[position];
}
