export default function ListItemIcon() {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
  };
}
