import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useTimeoutFn } from 'react-use';
import { useDebouncedValue } from 'rooks';
import { PdfViewerContext } from './types';

export function useShowNavigation() {
  const ref = useRef<HTMLDivElement>(null);

  const [showNavigation, setShowNavigation] = useState(false);
  const [debounced, forceShowNavigation] = useDebouncedValue(showNavigation, 500);

  const [, cancel, reset] = useTimeoutFn(() => {
    setShowNavigation(false);
    forceShowNavigation(false);
  }, 2000);

  const handleMouseActivity = useCallback(() => {
    setShowNavigation(true);
    forceShowNavigation(true);
    cancel();
    reset();
  }, [forceShowNavigation, cancel, reset]);

  const handleMouseLeave = useCallback(() => {
    cancel();
    setShowNavigation(false);
  }, [cancel]);

  const setRef = useCallback(
    (node: HTMLDivElement) => {
      if (ref.current) {
        ref.current.removeEventListener('click', handleMouseActivity);
        ref.current.removeEventListener('wheel', handleMouseActivity);
        ref.current.removeEventListener('mousemove', handleMouseActivity);
        ref.current.removeEventListener('mouseleave', handleMouseLeave);
      }

      if (node) {
        node.addEventListener('click', handleMouseActivity);
        node.addEventListener('wheel', handleMouseActivity);
        node.addEventListener('mousemove', handleMouseActivity);
        node.addEventListener('mouseleave', handleMouseLeave);
      }

      // @ts-expect-error this is fine
      ref.current = node;
    },
    [handleMouseActivity, handleMouseLeave],
  );

  return [setRef, debounced] as const;
}

export function useMakeUrl() {
  const loc = useLocation();

  return useCallback((url: string) => `${loc.protocol}//${loc.host}${url}`, [loc]);
}

export function useElementVisibility(elementId: string, label?: string) {
  const [visible, setVisible] = useState(false);
  const [elementLabel, setElementLabel] = useState<string | null>(null);
  const { ready } = useContext(PdfViewerContext);

  useEffect(() => {
    if (ready) {
      const container = document.getElementById(elementId);
      const visible = container?.checkVisibility() ?? false;
      setVisible(visible);

      if (container) {
        const iobserver = new IntersectionObserver(
          entries => {
            setVisible(entries[0].isIntersecting && (!label || elementLabel === label));
          },
          {
            threshold: 0,
          },
        );

        const mobserver = new MutationObserver(() => {
          setElementLabel(container.getAttribute('aria-label'));
        });

        mobserver.observe(container, {
          attributes: true,
        });

        iobserver.observe(container);

        return () => {
          iobserver.disconnect();
          mobserver.disconnect();
        };
      }
    }

    return undefined;
  }, [ready, elementId, label, elementLabel]);

  return visible && (!label || elementLabel === label);
}
