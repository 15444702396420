import { JwtPayload } from 'jwt-decode';
import { createContext } from 'react';
import type { User } from '@api/deianira/generated/graphql';
import type { ApiError } from '@features/errors/types';

export type AccessToken = JwtPayload & {
  profile: {
    id: string;
  };
};

export enum AuthState {
  AUTHENTICATING = 'authenticating',
  AUTHENTICATED = 'authenticated',
  NOT_AUTHENTICATED = 'not_authenticated',
  NO_PERMISSION = 'no_permission',
  ERROR = 'error',
}

export type AuthCommonContextT = {
  next: string;
  login: () => void;
  logout: (next?: string) => void;
};

export type AuthAuthenticatingContexT = AuthCommonContextT & {
  state: AuthState.AUTHENTICATING;
};

export type AuthNotAuthenticatedContextT = AuthCommonContextT & {
  state: AuthState.NOT_AUTHENTICATED;
};

export type AuthErrorContextT = AuthCommonContextT & {
  state: AuthState.ERROR;
  error: ApiError;
};

export type AuthNoPermissionT = AuthCommonContextT & {
  state: AuthState.NO_PERMISSION;
};

export type AuthAuthenticatedContextT = AuthCommonContextT & {
  state: AuthState.AUTHENTICATED;
  user: User;
};

export type AuthContextT =
  | AuthAuthenticatingContexT
  | AuthNotAuthenticatedContextT
  | AuthNoPermissionT
  | AuthErrorContextT
  | AuthAuthenticatedContextT;

export const AuthContext = createContext<AuthContextT>({} as AuthContextT);
