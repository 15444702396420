export default function Typography() {
  return {
    MuiTypography: {
      defaultProps: {
        variant: 'bodyRegular',
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 12,
        },
      },
    },
  };
}
