export default function InputAdornment() {
  return {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          margin: 0,
          maxHeight: 'unset',
          '&.flag': {
            marginLeft: '12px',
          },
        },
      },
    },
  };
}
