import { Theme } from '@mui/material/styles';

export default function PickersCallendarHeader(theme: Theme) {
  return {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          height: '20px',
          minHeight: '20px',
          maxHeight: 'unset',
          marginBottom: '10px',
          marginLeft: '2px',
        },
        label: {
          ...theme.typography.body2Medium,
        },
        switchViewButton: {
          color: theme.palette.action.disabled,
          padding: 0,
          width: 12,
          height: 12,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  };
}
