export default function Paper() {
  return {
    MuiPaper: {
      variants: [
        {
          props: { variant: 'dropdown' },
          style: {
            padding: '8px 0 !important',
            borderRadius: '4px !important',
            transitionDuration: '0s !important',
            border: 'none',
            '& .MuiMenu-list': {
              maxHeight: '180px',
              overflowY: 'auto',
              '& .MuiMenuItem-root': {
                borderRadius: 0,
              },
            },
          },
        },
      ],
    },
  };
}
