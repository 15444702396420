export default function MultiSectionDigitalClock() {
  return {
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          maxHeight: '284px !important',
        },
      },
    },
  };
}
