import { Divider, Stack, styled } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DownloadIcon } from '@components/actionicons';
import { ContextMenu } from '@components/ng/contextmenu';
import { ActionButton, BookmarkButton, SearchButton, ThumbnailsButton, ToggleButton } from './buttons';
import {
  FitToAutoIcon,
  FitToPageIcon,
  FitToWidthIcon,
  FullScreenIcon,
  PanIcon,
  SelectionIcon,
  ZoomInIcon,
  ZoomOutIcon,
  ZoomSelectIcon,
} from './icons';
import { PdfViewerContext } from './types';
import { useZoomContextMenuOptions } from './zoomcontextoptions';

const LightDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.lightDivider,
}));

const ToolbarContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderLeft: `1px solid ${theme.palette.secondary.light}`,
  padding: '8px',
}));

export interface ToolbarProps {
  disabled: boolean;
}

export function Toolbar({ disabled }: ToolbarProps) {
  const { t } = useTranslation();

  const { viewer, interactionMode, setFullscreen, fullscreen, setInteractionMode } = useContext(PdfViewerContext);

  const handleFullscreen = useCallback(() => {
    setFullscreen(!fullscreen);
  }, [fullscreen, setFullscreen]);

  const handleZoomIn = useCallback(() => {
    if (viewer) {
      viewer.magnification.zoomIn();
    }
  }, [viewer]);

  const handleZoomOut = useCallback(() => {
    if (viewer) {
      viewer.magnification.zoomOut();
    }
  }, [viewer]);

  const handleFitToWidth = useCallback(() => {
    if (viewer) {
      viewer.magnification.fitToWidth();
    }
  }, [viewer]);

  const handleFitToPage = useCallback(() => {
    if (viewer) {
      viewer.magnification.fitToPage();
    }
  }, [viewer]);

  const handleFitToAuto = useCallback(() => {
    if (viewer) {
      viewer.magnification.fitToAuto();
    }
  }, [viewer]);

  const handleDownload = useCallback(() => {
    if (viewer) {
      viewer.download();
    }
  }, [viewer]);

  const handlePan = useCallback(() => {
    setInteractionMode('Pan');
  }, [setInteractionMode]);

  const handleTextSelection = useCallback(() => {
    setInteractionMode('TextSelection');
  }, [setInteractionMode]);

  const { options: zoomOptions, onClick: handleZoomClick } = useZoomContextMenuOptions();

  return (
    <ToolbarContainer
      direction="column"
      spacing="8px"
    >
      <BookmarkButton disabled={disabled} />
      <ThumbnailsButton disabled={disabled} />
      <LightDivider />
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/fittoauto')}
        onClick={handleFitToAuto}
      >
        <FitToAutoIcon />
      </ActionButton>
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/fittowidth')}
        onClick={handleFitToWidth}
      >
        <FitToWidthIcon />
      </ActionButton>
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/fittopage')}
        onClick={handleFitToPage}
      >
        <FitToPageIcon />
      </ActionButton>
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/zoomout')}
        onClick={handleZoomOut}
      >
        <ZoomOutIcon />
      </ActionButton>
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/zoomin')}
        onClick={handleZoomIn}
      >
        <ZoomInIcon />
      </ActionButton>
      <ContextMenu
        disabled={disabled}
        title={t('tooltip/report/zoom')}
        size="medium"
        variant="text"
        color="inherit"
        options={zoomOptions}
        onClick={handleZoomClick}
      >
        <ZoomSelectIcon />
      </ContextMenu>
      <LightDivider />
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/fullscreen')}
        onClick={handleFullscreen}
      >
        <FullScreenIcon />
      </ActionButton>
      <ActionButton
        disabled={disabled}
        title={t('tooltip/report/download')}
        onClick={handleDownload}
      >
        <DownloadIcon />
      </ActionButton>
      <SearchButton disabled={disabled} />
      <LightDivider />
      <ToggleButton
        disabled={disabled}
        title={t('tooltip/report/pan')}
        active={interactionMode === 'Pan'}
        onClick={handlePan}
      >
        <PanIcon />
      </ToggleButton>
      <ToggleButton
        disabled={disabled}
        title={t('tooltip/report/textselect')}
        active={interactionMode === 'TextSelection'}
        onClick={handleTextSelection}
      >
        <SelectionIcon />
      </ToggleButton>
    </ToolbarContainer>
  );
}
