import { Theme } from '@mui/material/styles';

export default function InputBase(theme: Theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          padding: '0px',
        },
        input: {
          '& .MuiOutlinedInput-input': {
            padding: 0,
          },
        },
        adornedStart: {
          '& .MuiOutlinedInput-input': {
            paddingLeft: '8px',
          },
          '& .MuiInputAdornment-positionStart > svg': {
            marginLeft: '12px',
          },
        },
        sizeSmall: {
          fontSize: '12px',
        },
        sizeMedium: {
          fontSize: '14px',
        },
        sizeLarge: {
          fontSize: '16px',
        },
      },
    },
  };
}
