import type { Theme } from '@mui/material/styles';

export default function MenuItem(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '7px 12px',
          borderRadius: '6px',
          ...theme.typography.bodyRegular,
          '&:hover': {
            backgroundColor: theme.palette.secondary.lighter,
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.secondary.lighter,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.grey.A800,
            '&:hover': {
              backgroundColor: theme.palette.grey.A800,
            },
            '& svg': {
              color: theme.palette.primary.main,
            },
            '&:has(.MuiCheckbox-root)': {
              backgroundColor: 'inherit',
              '&:hover': {
                backgroundColor: theme.palette.secondary.lighter,
              },
            },
          },
        },
      },
    },
  };
}
