import { createContext, useContext } from 'react';

export const PdfViewerLicenseContext = createContext<string>('');

export interface PdfViewerLicenseProviderProps {
  licenseKey: string;
  children: React.ReactNode;
}

export function PdfViewerLicenseProvider({ licenseKey, children }: PdfViewerLicenseProviderProps) {
  return <PdfViewerLicenseContext.Provider value={licenseKey}>{children}</PdfViewerLicenseContext.Provider>;
}

export function usePdfViewerLicense() {
  return useContext(PdfViewerLicenseContext);
}
