export default function DateCalendar() {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'unset',
          maxHeight: 'unset',
          padding: '12px',
          paddingTop: '10px',
        },
      },
    },
  };
}
