import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import mantisComponentsOverride from '@mantis/overrides';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { ReactNode } from 'react';
import './fonts';
import makePalette from './palette';
import makeCustomShadows from './shadows';
import makeTypography from './typography';

export type ThemeCustomisationProps = {
  nonce?: string;
  children: ReactNode;
};

const makeStaticTheme = () => {
  const palette = makePalette();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        mb: 512 + 1,
        sm: 768 + 1,
        md: 1024 + 1,
        lg: 1266 + 1,
        xl: 1440 + 1,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 60,
        paddingTop: 8,
        paddingBottom: 8,
      },
      MuiDataGrid: {
        pinnedBackground: palette.common.white,
        containerBackground: palette.common.white,
      },
    },
    palette,
    customShadows: makeCustomShadows(palette),
    typography: makeTypography(`'Public Sans', sans-serif`),
    shape: {
      borderRadius: '12px',
      inputRadius: '6px',
    },
    borders: {
      default: `1px solid ${palette.secondary[200]}`,
    },
  });

  theme.components = mantisComponentsOverride(theme);
  return theme;
};

export const theme = makeStaticTheme();

export default function ThemeCustomisation({ nonce, children }: ThemeCustomisationProps) {
  // @emotion/cache needs a nonce to insert inline styles
  const cache = createCache({
    key: 'css',
    nonce,
    prepend: true,
  });

  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}
