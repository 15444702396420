export default function YearCalendar() {
  return {
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '100%',
          maxHeight: '236px',
          maxWidth: '256px',
        },
      },
    },
  };
}
