import { Theme } from '@mui/material/styles';

export default function DayCalendarHeader(theme: Theme) {
  return {
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          padding: '8px 0px',
          maxWidth: '280px',
          margin: '0px 2px',
        },
        header: {
          justifyContent: 'space-between',
        },
        weekContainer: {
          justifyContent: 'space-between',
          padding: '3px 6px',
          margin: 0,
          marginTop: '-1px',
        },
        weekDayLabel: {
          ...theme.typography.labelBold,
          color: theme.palette.text.disabled,
          margin: 0,
          height: '30px',
        },
        slideTransition: {
          minHeight: '190px',
        },
      },
    },
  };
}
