import { useCallback, useContext, useMemo } from 'react';
import { PopoverMenuLabelledItemT } from '@components/ng/popovermenu';
import { PdfViewerContext, type ZoomContextMenuId } from './types';

const zoomLevels = [10, 25, 50, 100, 125, 150, 200];
const options: PopoverMenuLabelledItemT<ZoomContextMenuId>[] = zoomLevels.map(level => ({
  id: `${level}` as ZoomContextMenuId,
  label: `${level} %`,
}));

export function useZoomContextMenuOptions() {
  const { viewer } = useContext(PdfViewerContext);

  const handleClick = useCallback(
    (key: ZoomContextMenuId) => {
      if (!viewer) {
        return;
      }

      const zoomLevel = parseInt(key, 10);
      if (!Number.isNaN(zoomLevel)) {
        viewer.magnification.zoomTo(zoomLevel);
      }
    },
    [viewer],
  );

  return useMemo(() => ({ options, onClick: handleClick }), [handleClick]);
}
