import RadioCheckedIcon from '@mantis/icons/radio-checked.svg?react';
import RadioUncheckedIcon from '@mantis/icons/radio-unchecked.svg?react';
import { Theme } from '@mui/material/styles';

export default function Radio(theme: Theme) {
  return {
    MuiRadio: {
      defaultProps: {
        icon: <RadioUncheckedIcon />,
        checkedIcon: <RadioCheckedIcon />,
      },
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-checked:hover': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
  };
}
