import { Button, SvgIcon, Tooltip } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '@components/actionicons';
import { BookmarkIcon, ThumbnailsIcon } from './icons';
import { PdfViewerContext } from './types';
import { useElementVisibility } from './utils';

export interface ActionButtonProps {
  title: string;
  disabled?: boolean;
  onClick: () => void;
  children: React.ReactElement;
}

export function ActionButton({ title, disabled, onClick, children }: ActionButtonProps) {
  return (
    <Tooltip
      title={title}
      placement="left"
      arrow
    >
      <span>
        <Button
          disabled={disabled}
          size="mediumSquare"
          color="secondary"
          variant="text"
          onClick={onClick}
        >
          <SvgIcon
            sx={{
              color: disabled ? 'inherit' : 'text.primary',
            }}
          >
            {children}
          </SvgIcon>
        </Button>
      </span>
    </Tooltip>
  );
}

export interface ToggleButtonProps {
  title: string;
  disabled?: boolean;
  active?: boolean;
  onClick: () => void;
  children: React.ReactElement;
}

export function ToggleButton({ title, disabled, active, onClick, children }: ToggleButtonProps) {
  return (
    <Tooltip
      title={title}
      placement="left"
      arrow
    >
      <span>
        <Button
          disabled={disabled}
          size="mediumSquare"
          color="secondary"
          variant="text"
          onClick={onClick}
        >
          <SvgIcon
            sx={{
              color: disabled ? 'inherit' : active ? 'primary.main' : 'text.primary',
            }}
          >
            {children}
          </SvgIcon>
        </Button>
      </span>
    </Tooltip>
  );
}

export interface BookmarkButtonProps {
  disabled?: boolean;
}

export function BookmarkButton({ disabled }: BookmarkButtonProps) {
  const { t } = useTranslation();

  const active = useElementVisibility('container_sideBarContent', 'Bookmark View Panel');

  const { viewer } = useContext(PdfViewerContext);

  const handleBookmarks = useCallback(() => {
    if (active) {
      viewer?.bookmark.closeBookmarkPane();
    } else {
      viewer?.thumbnailView.closeThumbnailPane();
      viewer?.bookmark.openBookmarkPane();
    }
  }, [viewer, active]);

  return (
    <ToggleButton
      disabled={disabled}
      title={t('tooltip/report/bookmarks')}
      onClick={handleBookmarks}
      active={active}
    >
      <BookmarkIcon />
    </ToggleButton>
  );
}

export interface ThumbnailsButtonProps {
  disabled?: boolean;
}

export function ThumbnailsButton({ disabled }: ThumbnailsButtonProps) {
  const { t } = useTranslation();

  const active = useElementVisibility('container_sideBarContent', 'Thumbnail View Panel');

  const { viewer } = useContext(PdfViewerContext);

  const handleThumbnails = useCallback(() => {
    if (active) {
      viewer?.thumbnailView.closeThumbnailPane();
    } else {
      viewer?.bookmark.closeBookmarkPane();
      viewer?.thumbnailView.openThumbnailPane();
    }
  }, [viewer, active]);

  return (
    <ToggleButton
      disabled={disabled}
      title={t('tooltip/report/thumbnails')}
      onClick={handleThumbnails}
      active={active}
    >
      <ThumbnailsIcon />
    </ToggleButton>
  );
}

export interface SearchButtonProps {
  disabled?: boolean;
}

export function SearchButton({ disabled }: SearchButtonProps) {
  const { t } = useTranslation();

  const active = useElementVisibility('container_search_box');

  const { viewer } = useContext(PdfViewerContext);

  const handleBookmarks = useCallback(() => {
    viewer?.textSearch.showSearchBox(!active);
  }, [viewer, active]);

  return (
    <ToggleButton
      disabled={disabled}
      title={t('tooltip/report/search')}
      onClick={handleBookmarks}
      active={active}
    >
      <SearchIcon />
    </ToggleButton>
  );
}
