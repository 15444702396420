import { ColorProps } from '@mantis/types/extended';
import getColors from '@mantis/utils/getColors';
import getShadow from '@mantis/utils/getShadow';
import { Theme } from '@mui/material/styles';

interface Props {
  variant: ColorProps;
  theme: Theme;
}

// Border color and shadow
function getColor({ variant, theme }: Props) {
  const colors = getColors(theme, variant);
  const { light } = colors;

  const shadows = getShadow(theme, `${variant}`);

  return {
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: light,
    },
    '&.Mui-focused': {
      boxShadow: shadows,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${light}`,
      },
    },
  };
}

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: theme.palette.common.white,
          borderRadius: theme.shape.inputRadius,
          ...theme.typography.bodyRegular,
          '& svg': {
            color: theme.palette.text.disabled,
          },
          ...getColor({ variant: 'primary', theme }),
          '&.Mui-error': {
            ...getColor({ variant: 'error', theme }),
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.grey[100],
            backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[300],

            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[300],
            },
          },

          '&.MuiInputBase-adornedStart:has(.MuiChip-root)': {
            paddingLeft: '6px',
            gap: '4px',
          },

          '&.MuiInputBase-sizeLarge': {
            '& .MuiOutlinedInput-input': {
              ...theme.typography.subheadingRegular,
              padding: '10px 12px',
            },
          },
        },
        input: {
          height: 'unset',
          padding: '7px 12px',
          ...theme.typography.bodyRegular,

          '&::placeholder': {
            color: theme.palette.action.disabled,
            opacity: 1,
          },
        },
        notchedOutline: {
          borderColor: theme.palette.grey[300],
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme }),
      },
    },
  };
}
