import { Theme } from '@mui/material/styles';
import { DownFillIcon } from '@components/actionicons';

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: DownFillIcon,
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          padding: 'unset !important',
          width: '100%',
          ...theme.typography.bodyRegular,
        },
        select: {
          padding: '7px 44px 7px 12px !important',
          '&:after': {
            content: '""',
            position: 'absolute',
            top: '0',
            bottom: '0',
            right: '32px',
            borderRight: '1px solid',
            borderRightColor: theme.palette.secondary.light,
          },
        },
        icon: {
          margin: 'unset !important',
          position: 'absolute !important',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '9px',
          color: `${theme.palette.text.disabled} !important`,
        },
        iconOpen: {
          transform: 'translateY(-50%) rotate(180deg)',
        },
      },
    },
  };
}
