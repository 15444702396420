import { Theme } from '@mui/material/styles';

export default function MultiSectionDigitalClockSection(theme: Theme) {
  return {
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          width: '40px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          paddingTop: '6px',
          paddingBottom: '1px',
          maxHeight: '224px',
        },
        item: {
          ...theme.typography.body2Description,
          padding: '0',
          margin: '0 auto',
          marginBottom: '4px',
          width: '26px',
          height: '26px',
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '6px',
          },
          '&:hover': {
            backgroundColor: theme.palette.secondary[100],
            borderRadius: '6px',
          },
        },
      },
    },
  };
}
