import getColors from '@mantis/utils/getColors';
import getShadow from '@mantis/utils/getShadow';
import {
  Box,
  BoxProps,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
  ToggleButtonGroup as MuiToggleButtonGroup,
  Stack,
  styled,
} from '@mui/material';

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(({ theme }) => ({
  flexWrap: 'wrap',
  width: '100%',
  borderBottom: '1px solid',
  borderColor: theme.palette.secondary.light,
  borderRadius: 0,
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',

  '& .MuiToggleButtonGroup-firstButton': {
    borderTopLeftRadius: '6px !important',
  },
  '& .MuiToggleButtonGroup-lastButton': {
    borderTopRightRadius: '6px !important',
  },

  '& .MuiButtonBase-root': {
    width: '28px',
    height: '28px',
    border: 'none',
    borderRadius: 0,
    '&.Mui-disabled': {
      color: theme.palette.grey[400],
      border: 'none',
    },
  },
}));

export const MenuList = styled(MuiMenuList)(({ theme }) => ({
  padding: '6px',
  maxHeight: '220px',
  minWidth: '150px',
  overflow: 'auto',
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderTopLeftRadius: '6px',
  borderTopRightRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
}));

export const NoResultsWrapper = styled(Stack)(() => ({
  padding: '7px 12px 7px 12px',
}));

export const MenuItem = styled(MuiMenuItem)({});

interface WrapperProps extends BoxProps {
  focused?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const Wrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'focused' && prop !== 'error' && prop !== 'disabled',
})<WrapperProps>(({ theme, disabled, error, focused }) => {
  const colors = getColors(theme, error ? 'error' : 'primary');
  const shadows = getShadow(theme, error ? 'error' : 'primary');

  const defaultBorderColour = theme.palette.secondary.light;

  const { light, main } = colors;

  return {
    width: '100%',
    border: '1px solid',
    borderRadius: '6px',
    borderColor: error ? main : defaultBorderColour,

    '&:hover': {
      borderColor: disabled ? defaultBorderColour : light,
    },

    ...(focused && !disabled
      ? {
          borderColor: error ? main : light,
          boxShadow: shadows,
        }
      : {}),
  };
});
