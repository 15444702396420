import { Box, Button, Typography, styled } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FirstPageIcon, LastPageIcon, NextPageIcon, PreviousPageIcon } from './icons';
import './navigationbar.scss';
import { PdfViewerContext } from './types';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2px',
  padding: '6px 8px',
  backgroundColor: theme.palette.swartz.light,
  borderRadius: '6px',
}));

const NavigationButton = styled(Button)(() => ({
  padding: '9px',
  minWidth: 0,
  backgroundColor: 'transparent',
  borderColor: 'transparent',
}));

export interface NavigationBarProps {
  enabled: boolean;
}

export function NavigationBar({ enabled }: NavigationBarProps) {
  const { t } = useTranslation();

  const { viewer, currentPage, totalPages } = useContext(PdfViewerContext);

  const handleFirstPage = useCallback(() => {
    viewer?.navigation.goToFirstPage();
  }, [viewer]);

  const handlePreviousPage = useCallback(() => {
    viewer?.navigation.goToPreviousPage();
  }, [viewer]);

  const handleNextPage = useCallback(() => {
    viewer?.navigation.goToNextPage();
  }, [viewer]);

  const handleLastPage = useCallback(() => {
    viewer?.navigation.goToLastPage();
  }, [viewer]);

  if (!totalPages) {
    return null;
  }

  return (
    <Container className={enabled ? 'pdfviewer-navigation-show' : 'pdfviewer-navigation'}>
      <NavigationButton
        disabled={currentPage === 1}
        color="swartz"
        variant="contained"
        size="medium"
        onClick={handleFirstPage}
        startIcon={<FirstPageIcon />}
      />
      <NavigationButton
        disabled={currentPage === 1}
        color="swartz"
        variant="contained"
        size="medium"
        onClick={handlePreviousPage}
        startIcon={<PreviousPageIcon />}
      />
      <Typography
        variant="body2Description"
        color="common.white"
        sx={{
          paddingLeft: '8px',
          paddingRight: '8px',
          minWidth: '90px',
          textAlign: 'center',
        }}
      >
        {t('label/report/pages', {
          page: currentPage,
          totalPages,
        })}
      </Typography>
      <NavigationButton
        disabled={currentPage === totalPages}
        color="swartz"
        variant="contained"
        size="medium"
        onClick={handleNextPage}
        startIcon={<NextPageIcon />}
      />
      <NavigationButton
        disabled={currentPage === totalPages}
        color="swartz"
        variant="contained"
        size="medium"
        onClick={handleLastPage}
        startIcon={<LastPageIcon />}
      />
    </Container>
  );
}
