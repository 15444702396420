export default function PickersLayout() {
  return {
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          display: 'unset',
          '& .MuiPickersLayout-actionBar': {
            height: '44px',
          },
        },
      },
    },
  };
}
