import { TypographyVariantsOptions } from '@mui/material/styles';

const makeTypography = (fontFamily: string): TypographyVariantsOptions => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  labelBold: {
    fontWeight: 700,
    fontSize: '11px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  headingRegular: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
  },
  headingBold: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
  },
  headingLargeBold: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
  },
  tagLabelBold: {
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  },
  subheadingRegular: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  subheadingBold: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },
  bodyBold: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
  },
  bodyRegular: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
  bodyMedium: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
  },
  body2Description: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
  body2Medium: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
  },
  body2Bold: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '20px',
  },
  body3Regular: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '19px',
  },
});

export default makeTypography;
