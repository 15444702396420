import { Theme } from '@mui/material/styles';

export default function Tab(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-scroller': {
            overflow: 'visible',
          },
          overflow: 'visible',

          '& .MuiTabs-indicator': {
            display: 'none',
            transition: 'none',
          },

          '& .MuiTabs-flexContainer': {
            height: '100%',
            '& .MuiButtonBase-root': {
              padding: '12px',
              minWidth: '80px',
            },
          },
        },
        flexContainer: {
          '& button:first-of-type': {
            borderTopLeftRadius: '12px',
          },

          '& :last-child': {
            borderTopRightRadius: '12px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...theme.typography.bodyMedium,
          minHeight: 46,
          textTransform: 'none',
          color: theme.palette.text.primary,
          backgroundColor: `rgba(255, 255, 255, 0.6)`,

          borderLeft: `1px solid ${theme.palette.secondary[200]}`,
          borderRight: `1px solid ${theme.palette.secondary[200]}`,
          borderTop: `1px solid ${theme.palette.secondary[200]}`,
          borderBottom: `1px solid ${theme.palette.common.white}`,

          '&:hover': {
            backgroundColor: `rgba(255, 255, 255, 0.8)`,
            color: theme.palette.primary[300],
          },

          '&.Mui-selected': {
            backgroundColor: theme.palette.common.white,
            boxShadow: theme.customShadows.tab,

            '&:hover': {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary[400],
            },
          },

          '&:focus-visible': {
            borderRadius: 4,
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: -3,
          },
        },
      },
    },
  };
}
