import { Theme } from '@mui/material/styles';

export default function FormLabel(theme: Theme) {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.bodyRegular,
          '&.Mui-focused': {
            color: theme.palette.text.secondary,
          },
          '&.Mui-error': {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
  };
}
