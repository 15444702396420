import { useLazyQuery, useQuery } from '@apollo/client';
import type { ApolloError } from '@apollo/client';
import type { UnwrapArray } from '@utils/unwrap-array';
import { useEffect, useMemo, useState } from 'react';
import type { GetUsersQuery } from '@api/deianira/generated/graphql';
import { GET_USER_PROFILE, QUERY_USERS } from './queries/users';

export type User = UnwrapArray<GetUsersQuery['users']['data']>;

export function useUserProfile(userId?: string) {
  const [user, setUser] = useState<User | null>(null);

  const [getUserProfile, { error, loading, called }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setUser(data.userProfile);
    },
  });

  useEffect(() => {
    if (userId) {
      getUserProfile();
    } else {
      setUser(null);
    }
  }, [userId, getUserProfile]);

  return [user, { error, loading, called }] as const;
}

export type UseUsersOptions = {
  page?: number;
  limit?: number;
};

export type UseUsersResult = [
  {
    users: User[];
    totalCount: number;
  },
  {
    error?: ApolloError;
    loading: boolean;
    called: boolean;
    refetch: () => void;
  },
];

export function useUsers({ page, limit }: UseUsersOptions): UseUsersResult {
  const [users, setCompanies] = useState<User[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { error, loading, called, refetch } = useQuery(QUERY_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      limit,
    },
    onCompleted: data => {
      setCompanies(data.users.data!);
      setTotalCount(data.users.totalCount!);
    },
  });

  return useMemo(
    () =>
      [
        {
          users,
          totalCount,
        },
        {
          error,
          loading,
          called,
          refetch,
        },
      ] as const,
    [users, totalCount, error, loading, called, refetch],
  );
}
