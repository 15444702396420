import { type InteractionMode, type PdfViewerComponent } from '@syncfusion/ej2-react-pdfviewer';
import { createContext } from 'react';

export type PdfViewerContextT = {
  ready: boolean;
  viewer: PdfViewerComponent | null;
  interactionMode: InteractionMode;
  fullscreen: boolean;
  currentPage: number;
  totalPages: number;
  setInteractionMode: (mode: InteractionMode) => void;
  setFullscreen: (value: boolean) => void;
};

export const PdfViewerContext = createContext<PdfViewerContextT>({
  ready: false,
  viewer: null,
  interactionMode: 'Pan',
  fullscreen: false,
  currentPage: 0,
  totalPages: 0,
  setInteractionMode: () => {},
  setFullscreen: () => {},
});

export type ZoomContextMenuId = '10' | '25' | '50' | '100' | '125' | '150' | '200';
